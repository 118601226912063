import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import filterUnits from 'utils/KubenAssessments/filterUnits';
import filterSummaryAnswersTexts from 'utils/KubenAssessments/filterSummaryAnswersTexts';
import getModifiedPersonAssessment from 'utils/KubenAssessments/getModifiedPersonAssessment';
import getQuestionGroupId from 'utils/KubenAssessments/getQuestionGroupId';
import checkIfAnswerIsDefault from 'utils/KubenAssessments/checkIfAnswerIsDefault';

import useFetchRegionUnits from 'hooks/api/assessment/useFetchRegionUnits';
import useSaveAssessment from 'hooks/api/assessmentResult/useSaveAssessment';
import useSaveIncompleteAssessment from 'hooks/api/assessmentResult/useSaveIncompleteAssessment';
import useRecalculateAssessment from 'hooks/api/assessmentResult/useRecalculateAssessment';
import useSaveState from 'hooks/api/assessmentResult/useSaveState';

import { currentUser } from 'state/global/currentUser';
// eslint-disable-next-line max-len
import { isDesirableAnswerEnabled as isDesirableAnswerEnabledRecoil } from 'state/KubenAssessments/questions/isDesirableAnswerEnabled';
import { answersAssessment } from 'state/KubenAssessments/assessments/answersAssessment';
import { templateAssessment } from 'state/KubenAssessments/assessments/templateAssessment';
// eslint-disable-next-line max-len
import { chosenAssessmentAction as chosenAssessmentActionRecoil } from 'state/KubenAssessments/chosenAssessmentAction/chosenAssessmentAction';
import { currentQuestionState } from 'state/KubenAssessments/questions/currentQuestions';
import { regionUnits as regionUnitsRecoil } from 'state/KubenAssessments/assessments/regionUnits';
import { selectedPerson as selectedPersonRecoil } from 'state/KubenAssessments/saved/savedSelectedPerson';
import { chosenRegion as chosenRegionRecoil } from 'state/KubenAssessments/choosingTemplate/region';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import UIBackButton from 'components/global/UIButtons/UIBackButton';
import UIButtonCheckbox from 'components/global/UIButtons/UIButtonCheckbox';
import UIButtonUnderline from 'components/KubenAssessments/UIAssessments/UIButtonUnderline';

import UINameHolder from 'components/KubenAssessments/UIAssessments/UINameHolder';
import SummaryEstimatedCard from 'components/KubenAssessments/SummaryPage/SummaryEstimatedCard';
import SummaryBox from 'components/KubenAssessments/SummaryPage/SummaryBox';
import SummaryEstimatedCardHeader from 'components/KubenAssessments/SummaryPage/SummaryEstimatedCardHeader';

const SummaryPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const selectedPerson = useRecoilValue(selectedPersonRecoil);
  const chosenRegionData = useRecoilValue(chosenRegionRecoil);
  const user = useRecoilValue(currentUser);
  const templateAssessmentData = useRecoilValue(templateAssessment);
  const chosenAssessmentAction = useRecoilValue(chosenAssessmentActionRecoil);
  const regionUnits = useRecoilValue(regionUnitsRecoil);
  const setCurrentQuestion = useSetRecoilState(currentQuestionState);
  const [answersAssessmentData, setAnswersAssessmentData] = useRecoilState(answersAssessment);
  const [summaryData, setSummaryData] = useState();
  const [onlyDefaultAnswers, setOnlyDefaultAnswers] = useState(false);
  const [isDesirableAnswerEnabled, setIsDesirableAnswerEnabled] = useRecoilState(isDesirableAnswerEnabledRecoil);
  const isReadOnly = answersAssessmentData?.state?.id === 'PendingApproval';

  // fetch units
  const {
    isLoading: isRegionUnitsLoading,
    isError: isRegionUnitsError,
    mutate: mutateRegionUnits,
  } = useFetchRegionUnits(templateAssessmentData?.id, chosenRegionData?.id);

  // saving assessment
  const {
    isLoading: isSendingAssessmentLoading,
    isError: isSendingAssessmentError,
    isSuccess,
    mutate,
  } = useSaveAssessment(answersAssessmentData);

  const {
    isSuccess: isSaveStateSuccess,
    mutate: mutateSaveState,
  } = useSaveState();

  // post assessment
  const { isError: isPostIncompleteAssessmentError, mutate: mutatePostIncompleteAssessment } =
    useSaveIncompleteAssessment();

  const { isError: isRecalculateAssessmentError, mutate: mutateRecalculateAssessment } = useRecalculateAssessment();

  useEffect(() => {
    if (
      Object.keys(answersAssessmentData).length === 0 ||
      Object.keys(templateAssessmentData).length === 0 ||
      Object.keys(regionUnits).length === 0
    ) {
      return;
    } else {
      filterSummaryAnswers(answersAssessmentData.applicableQuestions, templateAssessmentData.questions);
      setIsDesirableAnswerEnabled(answersAssessmentData.isDesirableVisible);
    }
  }, [answersAssessmentData, templateAssessmentData, regionUnits]);

  // fetching units for unit question type
  useEffect(() => {
    if (Object.keys(templateAssessmentData).length !== 0) {
      // check if saved regionUnits are diffrent to determine if we need to fetch new ones
      if (
        regionUnits?.templateId !== templateAssessmentData?.id ||
        regionUnits?.regionId !== templateAssessmentData?.settings?.regionIds[0]
      ) {
        mutateRegionUnits();
      }
    }
  }, [templateAssessmentData]);

  // redirect user to choose-assessment after successful assessment save
  useEffect(() => {
    if (isSuccess) history.push('/assessments/person/' + selectedPerson?.id);
  }, [isSuccess]);

  useEffect(() => {
    if (isSaveStateSuccess) {
      history.push('/assessments/');
    }
  }, [isSaveStateSuccess]);

  const goToTop = () => {
    const contentWrapper = document.getElementById('content-wrapper');
    if (contentWrapper) {
      contentWrapper.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const filterThroughTable = (arrayOfProp, dataProp) => {
    for (let i = 0; i < arrayOfProp.length; i++) {
      if (dataProp === arrayOfProp[i]) {
        return true;
      }
    }
    return false;
  };

  const checkIfBlockedAnswer = (answersArray, questionType, answerObject) => {
    if (questionType === 'SingleAnswer' || questionType === 'Nps') {
      const answer = answersArray.find((el) => el.optionId === answerObject.answerOptionId);
      if (answer != null && answer.blockingDescription != null) {
        return true;
      }
    }
    if (questionType === 'Multi') {
      const answer = answersArray.filter((el) => answerObject.answerOptionIds.includes(el.optionId));
      if (answer.some((el) => el.blockingDescription != null)) {
        return true;
      }
    }
    if (questionType === 'Judged') {
      const judgedAnswer = answersArray.find((el) => el.optionId === answerObject.judgedOptionId);
      const desiredAnswer = answersArray.find((el) => el.optionId === answerObject.desiredOptionId);

      if (
        (judgedAnswer != null && judgedAnswer.blockingDescription != null) ||
        (desiredAnswer != null && desiredAnswer.blockingDescription != null)
      ) {
        return true;
      }
    }
    return false;
  };

  // adjust api data to array
  const filterSummaryAnswers = (answersArray, templateAnswersData) => {
    const filteredArray = [];
    const filteredArrayByGroups = [];
    for (let i = 0; i < templateAnswersData.length; i++) {
      if (filterThroughTable(answersArray, templateAnswersData[i].id)) {
        const answer = answersAssessmentData?.answers?.find((el) => el.question.id === templateAnswersData[i].id);
        const obj = {
          groupId: templateAnswersData[i]?.groupId,
          groupName: templateAssessmentData.questionGroups.find((el) => el.id === templateAnswersData[i].groupId).name,
          questionId: templateAnswersData[i].id,
          questionType: templateAnswersData[i].type,
          questionName: templateAnswersData[i].name,
          questionDescription: templateAnswersData[i].helpText,
          answersTexts:
            templateAnswersData[i]?.options != null
              ? filterSummaryAnswersTexts(templateAnswersData[i]?.options, templateAssessmentData?.options)
              : filterUnits(regionUnits),
          answerRequired: templateAnswersData[i].answerRequired,
          maxValue: templateAnswersData[i]?.maxValue != null ? templateAnswersData[i]?.maxValue : null,
          minValue: templateAnswersData[i]?.minValue != null ? templateAnswersData[i]?.minValue : null,
          step: templateAnswersData[i]?.stepValue != null ? templateAnswersData[i]?.stepValue : 1,
          // eslint-disable-next-line max-len
          isPercentage:
            templateAssessmentData.questions.find((el) => el.id === templateAnswersData[i].id).displayType ===
            'Percentage'
              ? true
              : false,
          isAnsweredExternally: templateAnswersData[i].answeredExternally,
          isBlockedAnswer: checkIfBlockedAnswer(templateAnswersData[i]?.options, templateAnswersData[i].type, answer),
          isAnswerIsDefault: checkIfAnswerIsDefault(
            templateAnswersData[i].type,
            answer,
            templateAnswersData[i]?.defaultAnswer,
          ),
          answer: answer,
          isEditable: templateAnswersData[i]?.hideTimePeriod != null ? templateAnswersData[i]?.hideTimePeriod : false,
          annotation: answer?.annotation,
          isReadonly: chosenAssessmentAction === 'summaryReadonly' ? true : false,
        };
        filteredArray.push(obj);
      }
    }
    for (let i = 0; i < templateAssessmentData?.questionGroups?.length; i++) {
      const filterByGroups = filteredArray.filter(
        (element) => element.groupId === templateAssessmentData?.questionGroups[i]?.id,
      );
      const obj = {
        groupName: filterByGroups[0]?.groupName,
        elements: filterByGroups,
      };
      filteredArrayByGroups.push(obj);
    }
    const filteredSummaryData = {
      summaryDataArray: filteredArrayByGroups,
      doesContainJudged: filteredArray.some((el) => el.questionType === 'Judged'),
      doesContainBlockedAnswer: filteredArray.some((el) => el.isBlockedAnswer === true),
    };
    return setSummaryData(filteredSummaryData);
  };

  const handleSubmitForApproval = () => {
    mutateSaveState({...answersAssessmentData, state: {
      id: "PendingApproval",
      value: "Väntar på godkännande.",
    }});
  };

  const handleSubmitDeny = () => {
    mutateSaveState({...answersAssessmentData, state: {
      id: "ApprovalDenied",
      value: "Ej godkänd.",
    }});
  };

  // if useAuditFlow & hasAuditAccess complete button

  const handleAnswerClick = (element, action, selectedAnswer, isJudgedAnswer) => {
    let tmpPersonAssessment;
    if (isJudgedAnswer) {
      tmpPersonAssessment = getModifiedPersonAssessment(element, action, selectedAnswer, answersAssessmentData, true);
      setAnswersAssessmentData(tmpPersonAssessment);
    } else {
      tmpPersonAssessment = getModifiedPersonAssessment(element, action, selectedAnswer, answersAssessmentData);
      setAnswersAssessmentData(tmpPersonAssessment);
    }

    if (tmpPersonAssessment.state.id === 'Completed') {
      mutateRecalculateAssessment(tmpPersonAssessment);
    } else if (tmpPersonAssessment.state.id !== 'PendingApproval') {
      mutatePostIncompleteAssessment(tmpPersonAssessment);
    }
  };

  const handleDesirableCheckboxClicked = () => {
    if (chosenAssessmentAction !== 'summaryReadonly') {
      const tmpPersonAssessment = getModifiedPersonAssessment(
        null,
        'isDesirableVisible',
        !isDesirableAnswerEnabled,
        answersAssessmentData,
      );
      setAnswersAssessmentData(tmpPersonAssessment);
    }
    setIsDesirableAnswerEnabled(!isDesirableAnswerEnabled);
  };

  const handleSendAssessment = () => {
    mutate();
  };

  const handleRedirectToQuestionClick = (selectedQuestionId) => {
    if (chosenAssessmentAction === 'summaryReadonly') return;
    const questionGroupId = getQuestionGroupId(selectedQuestionId, answersAssessmentData?.applicableQuestionsByGroup);
    setCurrentQuestion({
      groupId: questionGroupId,
      questionId: selectedQuestionId,
    });
    history.push('/assessments/questions');
  };

  if (
    Object.keys(answersAssessmentData).length === 0 ||
    Object.keys(templateAssessmentData).length === 0 ||
    isSendingAssessmentLoading ||
    isRegionUnitsLoading
  ) {
    return <UILoadingModal />;
  }

  if (
    isSendingAssessmentError ||
    isPostIncompleteAssessmentError ||
    isRecalculateAssessmentError ||
    isRegionUnitsError
  ) {
    return <UIErrorModal message={t('UIModals.errorModalMessage')} />;
  }

  return (
    <UIStaticComponentsWrapper>
      <div className="max-w-[1920px] mx-auto w-full pb-20 pr-8 pl-20 print-this">
        <div className="self-start w-full mb-10 space-y-4">
          <div className="w-fit">
            <UIBackButton />
          </div>
          <div className="w-full flex justify-between items-end">
            {selectedPerson && Object.keys(selectedPerson).length !== 0 && (
              <UINameHolder
                id={selectedPerson?.id}
                fullName={selectedPerson?.name}
                idNumber={selectedPerson?.personId}
                gender={selectedPerson?.gender}
                customerNumber={selectedPerson?.customerNo}
                socialSecurityNumber={selectedPerson?.socialSecurityNumber}
                reportId={selectedPerson?.reportId}
                isButton={false}
              />
            )}
            <div className="ml-auto">
              <UIButtonUnderline
                name="Ladda ner"
                onSubmit={() => window.print()}
              />
            </div>
          </div>
        </div>
        {!!answersAssessmentData?.calculationResults?.length && (
          <SummaryEstimatedCardHeader
            doctorProfession={chosenAssessmentAction === 'summaryReadonly' ? '' : answersAssessmentData?.unit?.name}
          >
            {answersAssessmentData &&
              answersAssessmentData?.calculationResults?.map((el, idx) => (
                <SummaryEstimatedCard
                  key={idx}
                  title={el.resultText}
                  variant={templateAssessmentData.type}
                  doesContainJudged={summaryData?.doesContainJudged}
                  judgedResult={el.judgedResult}
                  desiredResult={el.desiredResult}
                  isHidden={el.adminOnly && user?.roles?.some((role) => role?.id === 'Administrator') === false}
                />
              ))}
          </SummaryEstimatedCardHeader>
        )}
        <div className="flex justify-end pt-4 dont-print-this">
          <UIButtonCheckbox
            description={t('QuestionsPage.showOnlyDefaultCheckboxText')}
            checked={onlyDefaultAnswers}
            setChecked={setOnlyDefaultAnswers}
            isDisabled={false}
            styleDesc={'text-sm'}
          />
          {summaryData?.doesContainJudged && (
            <div className="flex justify-end ml-5">
              <UIButtonCheckbox
                description={t('QuestionsPage.specifyDesirableCheckboxText')}
                checked={isDesirableAnswerEnabled}
                setChecked={handleDesirableCheckboxClicked}
                isDisabled={false}
                styleDesc={'text-sm'}
              />
            </div>
          )}
        </div>
        <div>
          {summaryData?.summaryDataArray?.map((el, idx) => (
            <div
              className="flex flex-col"
              key={idx}
            >
              {!(onlyDefaultAnswers && el.elements.every((item) => item.isAnswerIsDefault === true)) && (
                <div className="space-y-8 dont-print-break">
                  {el.groupName != null && (
                    <h2 className="text-2xl title font-semibold text-left mt-16">{el.groupName}</h2>
                  )}
                  {el?.elements?.map((element, idx) => (
                    <SummaryBox
                      key={idx}
                      variant={element.questionType}
                      header={element.questionName}
                      questionId={element.questionId}
                      description={element.questionDescription}
                      checkedAnswer={element.answer}
                      answersOptions={element.answersTexts}
                      isAnswerRequired={element.answerRequired}
                      isAnswerBlocked={element.isBlockedAnswer}
                      minValue={element?.minValue != null ? Number(element?.minValue) : null}
                      maxValue={element?.maxValue != null ? Number(element?.maxValue) : null}
                      step={element?.step}
                      isPercentage={element?.isPercentage}
                      isAnsweredExternally={element?.isAnsweredExternally}
                      isEditable={element?.isEditable}
                      userComments={element.annotation}
                      isReadonly={element?.isReadonly || isReadOnly}
                      onClick={handleAnswerClick}
                      onRedirectToQuestionClick={handleRedirectToQuestionClick}
                      isDesirableAnswerEnabled={isDesirableAnswerEnabled}
                      isOnlyDefaultAnswers={onlyDefaultAnswers}
                      isAnswerIsDefault={element.isAnswerIsDefault}
                    />
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="w-full flex justify-end py-10 dont-print-this">
          <button
            type="submit"
            onClick={goToTop}
            className={
              'text-center rounded-10px justify-center py-3 px-8 button-primary ' +
              'rounded-md border font-normal flex items-center mr-6 hov-opacity'
            }
          >
            {t('SummaryPage.goTopButton')}
          </button>
          {templateAssessmentData?.settings?.useAuditFlow ?
          <>
          {answersAssessmentData?.rights?.canAudit ?
          <>
          {answersAssessmentData.state.id === "PendingApproval" ?
          <>
            <button
              type="submit"
              onClick={handleSubmitDeny}
              className={
                'text-center rounded-10px justify-center py-3 px-8 button-primary ' +
                'rounded-md border font-normal flex items-center mr-6 hov-opacity'
              }
            >
              Neka 
            </button>
            <button
              type="submit"
              onClick={handleSendAssessment}
              className={
                'text-center rounded-10px justify-center py-3 px-8 button-primary ' +
                'rounded-md border font-normal flex items-center mr-6 hov-opacity'
              }
            >
              Godkänn
            </button>
          </> :
            <button
              type="submit"
              onClick={handleSendAssessment}
              className={
                'text-center rounded-10px justify-center py-3 px-8 button-primary ' +
                'rounded-md border font-normal flex items-center mr-6 hov-opacity'
              }
            >
              Färdigställ bedömning 
            </button>
          }
          </>
            :
            <button
              type="submit"
              onClick={handleSubmitForApproval}
              className={
                'text-center rounded-10px justify-center py-3 px-8 button-primary ' +
                'rounded-md border font-normal flex items-center mr-6 hov-opacity'
              }
            >
              Skicka för godkännande
            </button>
          }
          </>
          : (chosenAssessmentAction !== 'summaryReadonly' && 
          answersAssessmentData?.state?.id !== "ApprovalDenied") && (
            <button
              type="submit"
              onClick={handleSendAssessment}
              className={
                `
                ${
                  answersAssessmentData?.requiredQuestionsAnswered === answersAssessmentData?.requiredQuestions &&
                  !summaryData?.doesContainBlockedAnswer
                    ? ' opacity-100 hover:opacity-70'
                    : ' opacity-50 pointer-events-none '
                } text-center rounded-10px ` +
                'rounded-md button-primary font-normal flex items-center justify-center py-3 px-8'
              }
            >
              {answersAssessmentData.state.id === 'Completed' && t('SummaryPage.updateButton')}
              {answersAssessmentData.state.id === 'Incomplete' && t('SummaryPage.sendButton')}
            </button>
          )}
        </div>
      </div>
    </UIStaticComponentsWrapper>
  );
};

export default SummaryPage;
